<template>
  <div class="container">
    <div class="module">
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">最新订单量</div>
        <div class="dfcs">
          <span>{{ orderStatistics.all_order_num }}</span>
          <div
            :class="
              orderStatistics.day_yoy_all_order_num_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{ orderStatistics.day_yoy_all_order_num_percentage }}%</span
            >
            <img
              v-if="orderStatistics.day_yoy_all_order_num_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">待支付订单</div>
        <div class="dfcs">
          <span>{{ orderStatistics.wait_pay_order_num }}</span>
          <div
            :class="
              orderStatistics.day_yoy_wait_pay_order_num_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{
                orderStatistics.day_yoy_wait_pay_order_num_percentage
              }}%</span
            >
            <img
              v-if="orderStatistics.day_yoy_wait_pay_order_num_percentage >= 0"
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="item">
        <i class="el-icon-warning"></i>
        <div class="des">已发货订单</div>
        <div class="dfcs">
          <span>{{ orderStatistics.already_delivery_order_num }}</span>
          <div
            :class="
              orderStatistics.day_yoy_already_delivery_order_num_percentage >= 0
                ? 'trend'
                : 'down'
            "
          >
            同比<span
              ><span style="margin: 0 2px">⬆</span
              >{{
                orderStatistics.day_yoy_already_delivery_order_num_percentage
              }}%</span
            >
            <img
              v-if="
                orderStatistics.day_yoy_already_delivery_order_num_percentage >=
                0
              "
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
            <img
              v-else
              src="https://source.aidogcatpet.com/forit/up.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="state">
      订单状态：
      <div
        style="cursor: pointer"
        :class="item.id == orderStatus ? 'bgcblue abnormal' : 'abnormal'"
        @click="selectOrderStatus(item.id)"
        v-for="item in orderIndex"
        :key="item.id"
      >
        {{ item.name }} ({{ item.num }})
      </div>
    </div>
    <div class="date">
      <div class="logistics">
        物流公司：
        <el-select
          filterable
          remote
          clearable
          :remote-method="remoteMethod"
          v-model="logisticsValue"
          placeholder="请选择"
        >
          <el-option
            v-for="item in express"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="logistics" style="margin-left: 20px">
        订单标记：
        <el-select
          filterable
          remote
          clearable
          :remote-method="remoteMethod"
          v-model="order_tag"
          placeholder="请选择"
        >
          <el-option
            v-for="item in tags"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="date">
      <div class="down-order">
        下单日期：
        <el-date-picker
          v-model="orderDate"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <div class="deliver">
        发货日期：
        <el-date-picker
          v-model="deliveryDate"
          type="datetimerange"
          range-separator="至"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
    </div>
    <div class="select">
      <div class="input">
        <div>关键词：</div>
        <el-input
          v-model="input"
          clearable
          placeholder="平台订单号\商家订单号\收件人\收件人手机号\物流单号\备注"
        ></el-input>
      </div>

      <el-button
        class="query-button"
        type="primary"
        style="background-color: #0078fb"
        @click="getOrderList('nopage')"
        >查询</el-button
      >
      <el-select
        class="query-select"
        @change="querySelectChange"
        placeholder=""
      >
        <!-- <el-select class="query-select" @change="querySelectChange" v-model="queryValue" placeholder=""> -->
        <el-option
          v-for="item in queryOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button
        type="info"
        style="background-color: #0078fb"
        @click="orderExport"
        >导出</el-button
      >
      <el-button type="warning" @click="openCreateOrder">手工建单</el-button>
      <el-tooltip width="200px" effect="dark" placement="top">
        <div slot="content">
          订单批量导入流程：<br />1，登录为它系统-订单-订单批量导入，可一次批量导入全部订单<br />2，进入全小宠小程序（支付宝或微信中搜索全小宠小程序），通过为它账号登录-个人中心-待支付中-分别进行支付后，下单完成。<br />
        </div>
        <el-button type="warning" @click="uploadZFBVisible = true"
          >订单批量导入</el-button
        >
      </el-tooltip>

      <!--<el-popover placement="right" width="600" trigger="click">
        <el-image :src="message_img"></el-image>
        <el-button slot="reference" type="warning">手工建单</el-button>
      </el-popover>
      <el-popover placement="right" width="600" trigger="click">
        <el-image :src="message_img"></el-image>
        <el-button slot="reference" type="warning">批量导入</el-button>
      </el-popover>-->

      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: start;
        "
      >
        <el-link
          type="primary"
          @click="templateDownload"
          style="margin-left: 30px"
          >下载批量导入模板</el-link
        >
        <el-link
          type="primary"
          @click="templateInstructions = true"
          style="margin-left: 30px"
          >批量导入模板填写说明</el-link
        >
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        @current-change="curretnChange"
        @prev-click="curretnChange"
        @next-click="curretnChange"
        :hide-on-single-page="false"
        :current-page="page"
        @size-change="handleSizeChange"
        :page-sizes="[20, 30, 40]"
        layout="total,sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
    <div class="table">
      <el-table
        border
        :data="orderList"
        :cell-style="{ textAlign: 'left' }"
        :header-cell-style="{ textAlign: 'center' }"
        @selection-change="handleSelectionChange"
        stripe
        style="width: 100%"
      >
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column type="index" label="订单信息" width="350">
          <template slot-scope="scope">
            <div class="text">平台订单号：{{ scope.row.order_code }}</div>
            <div class="text">商家订单号：{{ scope.row.store_order_code }}</div>
            <div class="text" v-if="scope.row.is_mode == 1">下单模式：采购</div>
            <div class="text" v-if="scope.row.is_mode == 2">下单模式：共享</div>
            <div class="text">支付流水号：{{ scope.row.transaction_id }}</div>
            <div class="text">下单时间：{{ scope.row.order_time }}</div>
            <div class="text">取消时间：{{ scope.row.cancel_time }}</div>
            <div class="text">订单标记：{{ scope.row.flag_name || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_code" label="货品信息" width="250">
          <template slot-scope="scope">
            <div class="text">订单类型：{{ scope.row.order_type }}</div>
            <div class="text">订单来源：{{ scope.row.order_source_value }}</div>
            <div class="text">订单金额：{{ scope.row.order_money }}</div>
            <div class="text">
              运费：{{ scope.row.order_shipping_fee || "-" }}
            </div>
            <div class="text" v-if="scope.row.is_mode != 0">
              销售金额：{{ scope.row.wholesale_price }}
            </div>
            <div class="text" v-if="scope.row.is_mode != 0">
              佣金：{{ scope.row.diff_amount }}
            </div>
            <div class="text">商品种类：{{ scope.row.item_number }}</div>
            <div class="text">货品总数量：{{ scope.row.product_attr_num }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_code" label="订单状态" width="290">
          <template slot-scope="scope">
            <div class="text">订单状态：{{ scope.row.order_status }}</div>
            <div class="text">退款方式：{{ scope.row.refund_mode_value }}</div>
            <div class="text">
              仓库状态：{{ scope.row.warehouse_status_name }}
            </div>
            <div class="text">发货时间：{{ scope.row.shipping_time }}</div>
            <div class="text">快递公司：{{ scope.row.express }}</div>
            <div class="text">快递单号：{{ scope.row.express_no }}</div>
            <div class="text">备注：{{ scope.row.remark }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="order_code" label="收货信息">
          <template slot-scope="scope">
            <div class="text">收件人：{{ scope.row.consigner }}</div>
            <div class="text">收件人电话：{{ scope.row.phone }}</div>
            <div class="text">订购人：{{ scope.row.orderer }}</div>
            <div class="text">订购人身份证号：{{ scope.row.card }}</div>
            <div class="text">
              收件地址：{{
                scope.row.province +
                scope.row.city +
                scope.row.area +
                scope.row.address
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="订单操作" width="100">
          <template slot-scope="scope">
            <div class="buttons">
              <!-- <el-button
                v-if="scope.row.warehouse_status < 5 && scope.row.order_status !== '已取消'"
                @click="changeOrderVisible(scope.row)"
                type="text"
                size="small"
              >修改信息</el-button>-->
              <el-button
                v-if="
                  scope.row.warehouse_status < 5 &&
                  scope.row.order_status !== '已取消' &&
                  scope.row.order_source != 7
                "
                @click="cancelOrder(scope.row)"
                type="text"
                size="small"
                >取消订单</el-button
              >
              <el-button
                @click="getOrderDetail(scope.row)"
                type="text"
                size="small"
                >查看详情</el-button
              >
              <!-- <el-button @click="orderAgain(scope.row)" v-if="scope.row.order_source != 7" type="text"
                size="small">再来一单</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @current-change="curretnChange"
        @prev-click="curretnChange"
        @next-click="curretnChange"
        :hide-on-single-page="false"
        :current-page="page"
        @size-change="handleSizeChange"
        :page-sizes="[20, 30, 40]"
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 订单详情页窗口 -->
    <el-dialog
      title="订单详情"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="order-detail"
      :visible.sync="detailVisible"
      width="80%"
    >
      <div>
        <div class="detail">
          <div>
            <div v-if="currentOrderDetail.order_code">
              平台订单号：{{ currentOrderDetail.order_code }}
            </div>
            <div v-if="currentOrderDetail.store_order_code">
              商家订单号：{{ currentOrderDetail.store_order_code }}
            </div>
            <div v-if="currentOrderDetail.order_money">
              订单金额：￥{{ currentOrderDetail.order_money }}
            </div>
            <div v-if="currentOrderDetail.is_mode != 0">
              销售金额：￥{{ currentOrderDetail.wholesale_price }}
            </div>
            <div v-if="currentOrderDetail.order_shipping_fee">
              运费：￥{{ currentOrderDetail.order_shipping_fee }}
            </div>
            <div v-if="currentOrderDetail.is_mode != 0">
              佣金：￥{{ currentOrderDetail.diff_amount }}
            </div>
            <!-- <div v-if="currentOrderDetail.is_mode == 1">订单模式：采购</div> -->
            <div v-if="currentOrderDetail.is_mode == 2">订单模式：共享</div>
            <div v-else>订单模式：采购</div>
            <div class="down-order" v-if="currentOrderDetail.order_time">
              下单时间：{{ currentOrderDetail.order_time }}
            </div>
          </div>

          <div>
            <div v-if="currentOrderDetail.real_name">
              收件人姓名：{{ currentOrderDetail.real_name }}
            </div>
            <div v-if="currentOrderDetail.phone">
              收件人手机号：{{ currentOrderDetail.phone }}
            </div>
            <div v-if="currentOrderDetail.orderer">
              订购人姓名：{{ currentOrderDetail.orderer }}
            </div>
            <div v-if="currentOrderDetail.card">
              订购人身份证号：{{ currentOrderDetail.card }}
            </div>
            <div>
              收货地址：{{
                currentOrderDetail.province +
                currentOrderDetail.city +
                currentOrderDetail.area +
                currentOrderDetail.address
              }}
            </div>
          </div>

          <div>
            <div v-if="currentOrderDetail.order_status">
              订单状态：{{ currentOrderDetail.order_status }}
            </div>
            <div>物流公司：{{ currentOrderDetail.express }}</div>
            <div>物流单号：{{ currentOrderDetail.express_no }}</div>
          </div>
        </div>
        <div class="select" style="margin-bottom: 10px">
          <div class="input">
            <div>关键词：</div>
            <el-input
              v-model="productInput"
              placeholder="商品条码/商品名称"
            ></el-input>
          </div>
          <!-- <div class="logistics" style="margin-left: 20px">
            状态：
            <el-select v-model="orderState" clearable placeholder="请选择">
              <el-option
                v-for="item in orderStateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>-->
          <el-button type="primary" @click="getOrderDetail('nopage')"
            >查询</el-button
          >
          <!-- <el-button type="info" @click="orderDetailExport">导出</el-button> -->
        </div>
        <el-table
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          :data="detailList"
          stripe
          style="width: 100%"
        >
          <el-table-column type="index" label="编号"></el-table-column>
          <!-- <el-table-column prop="item_id" label="商品ID"></el-table-column> -->
          <el-table-column prop="item_name" label="商品名称"></el-table-column>
          <el-table-column
            prop="spec_name"
            width="100"
            label="商品规格"
          ></el-table-column>
          <el-table-column prop="item_code" label="商品条码"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <img
                :src="scope.row.item_img"
                style="width: 50px; height: 50px"
                class="head_pic"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="item_price"
            width="120"
            label="单价"
          ></el-table-column>
          <el-table-column
            prop="item_num"
            width="100"
            label="数量"
          ></el-table-column>
          <el-table-column
            prop="total_price"
            width="120"
            label="总价"
          ></el-table-column>
          <el-table-column
            prop="status"
            width="120"
            label="状态"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
          ></el-table-column>
        </el-table>
        <!-- <div class="pagination">
          <el-pagination background @current-change="curretnDetailChange" @prev-click="curretnDetailChange"
            @next-click="curretnDetailChange" :hide-on-single-page="false" :current-page="detailPage"
            layout="total,prev, pager, next" :total="detailTotal"></el-pagination>
        </div> -->
      </div>
    </el-dialog>
    <!-- 批量导入详情页面 -->
    <el-dialog
      title="批量导入预览详情页"
      @close="importDialogClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <div>
        <div class="select" style="margin-bottom: 10px">
          <!-- <div class="input">
            <div>关键词：</div>
            <el-input v-model="productInput" placeholder="商品条码/商品名称"></el-input>
          </div> -->
          <div class="logistics" style="margin-left: 20px">
            库存状态：
            <el-select
              v-model="order_item_stock_status"
              clearable
              placeholder="请选择"
            >
              <el-option label="正常" :value="1"></el-option>
              <el-option label="异常" :value="2"></el-option>
            </el-select>
          </div>
          <div class="logistics" style="margin-left: 20px">
            校验结果：
            <el-select
              v-model="order_item_check_result"
              clearable
              placeholder="请选择"
            >
              <el-option label="正常" :value="1"></el-option>
              <el-option label="异常" :value="2"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="getImportList(1)">查询</el-button>
          <!-- <el-button type="info" @click="orderDetailExport">导出</el-button> -->
        </div>
        <el-table
          border
          :data="importList"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column type="index" label="编号"></el-table-column>
          <el-table-column
            prop="shop_order_code"
            width="200"
            label="商家订单号"
          ></el-table-column>
          <el-table-column
            prop="transaction_id"
            width="200"
            label="支付流水号"
          ></el-table-column>
          <el-table-column
            prop="order_item_name"
            width="300"
            label="商品名称"
          ></el-table-column>
          <el-table-column
            prop="order_item_code"
            width="200"
            label="商品条码"
          ></el-table-column>
          <el-table-column prop="order_item_num" label="数量"></el-table-column>
          <el-table-column
            prop="order_item_price"
            label="单价"
          ></el-table-column>
          <el-table-column
            prop="order_pay_price"
            label="支付金额"
          ></el-table-column>
          <el-table-column prop="consigner" label="收件人"></el-table-column>
          <el-table-column
            prop="phone"
            width="200"
            label="收件人手机号"
          ></el-table-column>
          <el-table-column
            prop="real_name"
            width="300"
            label="订购人姓名"
          ></el-table-column>
          <el-table-column
            prop="card"
            width="300"
            label="订购人身份证号"
          ></el-table-column>
          <el-table-column prop="province" label="省市"></el-table-column>
          <el-table-column prop="city" label="城市"></el-table-column>
          <el-table-column prop="area" label="区域"></el-table-column>

          <el-table-column
            prop="address"
            width="300"
            label="详细地址"
          ></el-table-column>
          <el-table-column prop="order_status" label="状态"></el-table-column>
          <el-table-column
            prop="order_remark"
            width="200"
            label="备注"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            width="300"
            label="创建时间"
          ></el-table-column>
          <el-table-column
            prop="stock_status"
            fixed="right"
            label="库存状态"
          ></el-table-column>
          <el-table-column prop="check_result" fixed="right" label="校验结果">
            <template slot-scope="scope">
              <div
                :class="scope.row.check_status !== -1 ? 'bgcgreen' : 'bgcred'"
              >
                {{ scope.row.check_result }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="order_date" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="removeOrder(scope.row)"
                type="text"
                size="small"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            @current-change="curretnImportChange"
            @prev-click="curretnImportChange"
            @next-click="curretnImportChange"
            :hide-on-single-page="false"
            :current-page="importPage"
            layout="total,prev, pager, next"
            :total="ImportTotal"
          ></el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="orderPrevieweExport"
          >缺货导出</el-button
        >
        <el-button
          type="primary"
          @click="orderImportConfirm"
          :loading="orderLoading"
          :disabled="orderLoading"
          >{{ orderLoading ? "订单导入中" : "确认下单" }}</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="订单批量导入预览详情页"
      @close="importDialogClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible2"
      width="80%"
    >
      <div>
        <div class="select" style="margin-bottom: 10px; margin-top: -20px">
          <!-- <div class="input">
            <div>关键词：</div>
            <el-input v-model="productInput" placeholder="商品条码/商品名称"></el-input>
          </div> -->
          <div class="logistics" style="margin-left: 20px">
            库存状态：
            <el-select
              v-model="order_item_stock_status"
              clearable
              placeholder="请选择"
            >
              <el-option label="正常" :value="1"></el-option>
              <el-option label="异常" :value="2"></el-option>
            </el-select>
          </div>
          <div class="logistics" style="margin-left: 20px">
            校验结果：
            <el-select
              v-model="order_item_check_result"
              clearable
              placeholder="请选择"
            >
              <el-option label="正常" :value="1"></el-option>
              <el-option label="异常" :value="2"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="getImportList2(1)">查询</el-button>
          <div class="order-num" style="color: blue">
            导入订单总数：{{ importZFBTotal }}
          </div>
          <div class="order-num" v-if="abnormalCount">
            异常数量：{{ abnormalCount }}
          </div>
          <!-- <el-button type="info" @click="orderDetailExport">导出</el-button> -->
        </div>
        <div class="tips-box">
          <div class="first-div">校验异常：</div>
          <div>
            1. 提示“地区不能为空”时， 需手动拆分地址的省市区，用空格或者“，”隔开
          </div>
          <div>2. 提示“订单重复入库”时， 请更改导入模板里的订单号</div>
        </div>
        <el-table
          border
          :data="importZFBList"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center' }"
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column type="index" label="编号"></el-table-column>
          <el-table-column
            prop="shop_order_code"
            width="200"
            label="商家订单号"
          ></el-table-column>
          <!-- <el-table-column prop="transaction_id" width="200" label="支付流水号"></el-table-column> -->
          <el-table-column
            prop="order_item_name"
            width="300"
            label="商品名称"
          ></el-table-column>
          <el-table-column
            prop="order_item_code"
            width="200"
            label="商品条码"
          ></el-table-column>
          <el-table-column prop="order_item_num" label="数量"></el-table-column>
          <el-table-column
            prop="order_item_price"
            label="单价"
          ></el-table-column>
          <el-table-column
            prop="order_pay_price"
            label="支付金额"
          ></el-table-column>
          <el-table-column prop="consigner" label="收件人"></el-table-column>
          <el-table-column
            prop="phone"
            width="200"
            label="收件人手机号"
          ></el-table-column>
          <el-table-column
            prop="real_name"
            width="300"
            label="订购人姓名"
          ></el-table-column>
          <el-table-column
            prop="card"
            width="300"
            label="订购人身份证号"
          ></el-table-column>
          <el-table-column prop="province" label="省市"></el-table-column>
          <el-table-column prop="city" label="城市"></el-table-column>
          <el-table-column prop="area" label="区域"></el-table-column>

          <el-table-column
            prop="address"
            width="300"
            label="详细地址"
          ></el-table-column>
          <el-table-column prop="order_status" label="状态"></el-table-column>
          <el-table-column
            prop="order_remark"
            width="200"
            label="备注"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            width="300"
            label="创建时间"
          ></el-table-column>
          <el-table-column
            prop="stock_status"
            fixed="right"
            label="库存状态"
          ></el-table-column>
          <el-table-column prop="check_result" fixed="right" label="校验结果">
            <template slot-scope="scope">
              <div
                :class="scope.row.check_status !== -1 ? 'bgcgreen' : 'bgcred'"
              >
                {{ scope.row.check_result }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="order_date" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                @click="removeZFBOrder(scope.row)"
                type="text"
                size="small"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            @current-change="curretnZFBImportChange"
            @prev-click="curretnZFBImportChange"
            @next-click="curretnZFBImportChange"
            :hide-on-single-page="false"
            :current-page="importPage"
            layout="total,prev, pager, next"
            :total="importZFBTotal"
          ></el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <!-- <el-button type="danger" @click="orderZFBPrevieweExport">缺货导出</el-button> -->
        <el-button
          type="primary"
          @click="orderZFBImportConfirm"
          :loading="orderLoading"
          :disabled="orderLoading"
          >{{ orderLoading ? "订单导入中" : "确认下单" }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="批量导单"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @open="openCreateOrderList"
      :visible.sync="uploadZFBVisible"
      width="40%"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          v-show="realTimeMessage.is_show"
          style="
            color: red;
            margin: 0px 0 0 20px;
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 2px;
            line-height: 28px;
          "
        >
          {{ realTimeMessage.title + realTimeMessage.content }}
        </div>
        <div class="logistics" style="padding-left: 20px; font-size: 18px">
          下单模式：
          <el-select
            v-model="order_model"
            @change="orderTypeChange"
            :placeholder="orderType[0].label"
          >
            <el-option
              v-for="item in orderType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div
          class="logistics"
          style="padding-left: 20px; margin-top: 20px; font-size: 18px"
        >
          支付方式：
          <el-select
            v-model="order_pay_mode"
            :disabled="orderTypeDisabled"
            @change="payTypeChange"
          >
            <el-option label="支付宝" :value="1"></el-option>
            <el-option label="微信" :value="2"></el-option>
          </el-select>
        </div>
        <div
          class="logistics"
          v-if="
            shopInfo.shop_id == 72 ||
            shopInfo.shop_id == 300 ||
            shopInfo.shop_id == 29 ||
            shopInfo.shop_id == 879 ||
            shopInfo.shop_id == 22 ||
            shopInfo.shop_id == 842 ||
            shopInfo.shop_id == 810
          "
          style="padding-left: 20px; font-size: 18px"
        >
          发货渠道：
          <el-select v-model="channel_id">
            <el-option label="跨境" :value="1"></el-option>
            <el-option label="大贸" :value="2"></el-option>
          </el-select>
        </div>
        <div
          v-show="order_model == 1"
          style="color: red; margin: 30px 0 0 -30px; font-size: 15px"
        >
          注意：采购模式下单只支持导入支付宝小程序
        </div>
      </div>

      <span slot="footer" class="footer">
        <el-button
          @click="uploadZFBVisible = false"
          :disabled="importZFBLoading"
          >取消导入</el-button
        >
        <el-upload
          :disabled="importZFBLoading"
          :action="BASE_URL2 + '/merapi/order/alipay_import'"
          :before-upload="beforeZFBUpload"
          :show-file-list="false"
          :on-success="uploadZFBSuccess"
          :headers="{ Authorization: token }"
          name="excel_file"
          :data="{
            shop_id: shopInfo.shop_id,
            order_pay_mode: order_pay_mode,
            channel_id: channel_id,
            order_model: order_model,
          }"
          accept=".xls, .xlsx"
        >
          <el-button
            :loading="importZFBLoading"
            :disabled="importZFBLoading"
            type="warning"
            >确认导入</el-button
          >
        </el-upload>
        <el-upload
          :disabled="importZFBLoading"
          :action="BASE_URL2 + '/merapi/order/self_import'"
          :before-upload="beforeZFBUpload"
          :show-file-list="false"
          :on-success="uploadZFBSuccess"
          :headers="{ Authorization: token }"
          name="excel_file"
          :data="{
            shop_id: shopInfo.shop_id,
            order_pay_mode: order_pay_mode,
            order_model: order_model,
          }"
          accept=".xls, .xlsx"
        >
          <el-button
            v-if="shopInfo.is_self"
            :loading="importZFBLoading"
            :disabled="importZFBLoading"
            type="warning"
            >自营店铺导入</el-button
          >
        </el-upload>
      </span>
    </el-dialog>
    <!-- 手工建单窗口 -->
    <el-dialog
      class="formDialog"
      :close-on-click-modal="false"
      width="70%"
      :close-on-press-escape="false"
      :visible.sync="dialogFormVisible"
    >
      <div slot="title" style="display: flex; align-items: center">
        <div>手工建单</div>
      </div>
      <div class="mes-title">
        即日起已有库存的手工建单请在“全小宠”支付宝小程序下单支付，扫码下方二维码进入小程序。
      </div>
      <div class="weixin-img">
        <img :src="zfb" style="width: 300px; height: 350px" class="enlarge" />
      </div>
    </el-dialog>
    <!-- 批量导入模板使用说明窗口 -->
    <el-dialog
      title="批量导入模板填写说明"
      width="60%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="templateInstructions"
    >
      <el-image :src="instructions_1"></el-image>
      <el-image :src="instructions_2"></el-image>
      <!-- <el-image :src="instructions_3"></el-image> -->
    </el-dialog>
    <el-dialog
      title="批量查询"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="batchQueryVisible"
    >
      <el-input
        type="textarea"
        :rows="20"
        placeholder="平台订单号\商家订单号\收件人\收件人手机号\物流单号"
        v-model="textareaOrderList"
      >
      </el-input>

      <div slot="footer" class="dialog-footer">
        <el-button @click="batchQueryVisible = false">取 消</el-button>
        <el-button type="primary" @click="getOrderList2('nopage')"
          >开始查询</el-button
        >
      </div>
    </el-dialog>
    <ModifyOrderInfo
      :orderInfo="currentOrderDetail"
      :editOrderVisible="editOrderVisible"
      @changeDialogVisible="setVisible"
    ></ModifyOrderInfo>
  </div>
</template>

<script>
let loading1;
import store from "@/store/index.js";
import { BASE_URL, BASE_URL2 } from "../request/request.config";
import ModifyOrderInfo from "../components/ModifyOrederInfo";
import { formatDateArray, urlEncode } from "@/utils/util.js";
export default {
  name: "order",
  components: {
    ModifyOrderInfo,
  },
  data() {
    // 校验手机号
    const checkPhone = async (rule, value, callback) => {
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9])\d{8}$/;
      const reg1 = /^(19[0-35-9])\d{8}$/;
      const boolean = reg.test(value);
      const boolean2 = reg1.test(value);

      if (boolean2) {
        callback(new Error("19开头的手机号不能用于清关,请更换手机号"));
      } else if (!boolean) {
        callback(new Error("请输入正确的手机号"));
      }
    };
    // 校验身份证号规则
    const checkCard = async (rule, value, callback) => {
      const reg =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

      const boolean = reg.test(value);

      if (!boolean) {
        callback(new Error("请输入正确的身份证号"));
      }
    };
    // 校验订单号
    const checkOrderNo = async (rule, value, callback) => {
      let reg = /^(?!_)(?!.*?_$)^(?!-)(?!.*?-$)[a-zA-Z0-9-]+$/;

      let boolean = reg.test(value);

      if (!boolean) {
        callback(
          new Error('商铺原始单号只能以数字、字母"-"构成,且不能以"-"开头和结尾')
        );
      }
    };
    // 校验支付订单号
    const checkTransaction = async (rule, value, callback) => {
      let reg = /^[0-9]*$/;

      let boolean = reg.test(value);

      if (!boolean) {
        callback(new Error("支付流水号只能输入数字"));
      }
    };
    return {
      order_tag: "",

      tags: [
        { id: "", name: "全部" },
        { id: "快递停发", name: "快递停发" },
      ],
      order_item_stock_status: "",
      order_item_check_result: "",
      dialogVisible: false,
      realTimeMessage: {
        is_show: false,
        title: "",
        content: "",
      },
      dialogVisible2: false,
      editOrderVisible: false,
      uploadVisible: false,
      uploadZFBVisible: false,
      templateInstructions: false,
      importZFBLoading: false,
      batchQueryVisible: false,
      textareaOrderList: "",
      order_pay_mode: 1,
      channel_id: 1,
      BASE_URL2: BASE_URL2,
      zfb: require("@/assets/images/zfb.jpg"),
      BASE_URL: BASE_URL,
      instructions_2: require("@/assets/images/instructions_2.png"),
      instructions_1: require("@/assets/images/instructions_1.png"),
      // instructions_3: require('@/assets/images/instructions_3.png'),
      payment_flow: require("@/assets/images/payment_flow.png"),
      message_img: require("@/assets/images/message.png"),
      queryValue: "1",
      textarea: "",
      detailVisible: false,
      dialogFormVisible: false,
      orderLoading: false,
      subLoading: false,
      importZFBTotal: 0,
      importLoading: false,
      checkProducts: [],
      orderForm: {
        shop_order: "",
        consigner: "",
        phone: "",
        card_no: "",
        province: "",
        transaction_id: "",
        city: "",
        area: "",
        address: "",
        remark: "",
        orderer: "",
        order_price: "",
      },
      orderFormRules: {
        shop_order: [
          { required: true, validator: checkOrderNo, trigger: "blur" },
        ],
        consigner: [
          { required: true, message: "请输入收件人姓名", trigger: "blur" },
        ],
        transaction_id: [
          { required: true, validator: checkTransaction, trigger: "blur" },
        ],

        orderer: [
          { required: true, message: "请输入订购人真实姓名", trigger: "blur" },
        ],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        card_no: [{ required: true, validator: checkCard, trigger: "blur" }],
        province: "",
        city: "",
        area: "",
        address: [
          { required: true, message: "请输入收货详细地址", trigger: "blur" },
        ],
      },
      productDetail: {},
      formLabelWidth: "150px",
      loading: false,
      queryOptions: [
        {
          value: "2",
          label: "批量查询",
        },
      ],
      productInput: "",
      importList: [],
      // eslint-disable-next-line vue/no-dupe-keys
      order_item_check_result: "",
      // eslint-disable-next-line vue/no-dupe-keys
      order_item_stock_status: "",
      detailList: [],
      orderDate: [],
      input: "",
      logisticsValue: "",
      total: 0,
      importPage: 1,
      importLimit: 10,
      ImportTotal: 0,
      detailTotal: 0,
      orderType: [
        {
          value: "1",
          label: "采购库存下单",
        },
        {
          value: "2",
          label: "共享库存下单",
        },
      ],
      order_model: "1",
      express: [],
      detailPage: 1,
      abnormalCount: 0,
      orderStatus: "",
      importZFBList: [],
      // orderState: '',
      orderStateOptions: [
        {
          value: "1",
          label: "正常",
        },
        {
          value: "2",
          label: "已退款",
        },
      ],
      page: 1,
      actIng: false,
      multipleSelection: [],
      orderStatistics: {},
      limit: 20,
      tempValue: "",
      deliveryDate: [],
      currentOrderDetail: {},
      orderIndex: [],
      productList: [],
      form_id: "",
      orderList: [],
      is_signed: false,
      orderTypeDisabled: true,
      fileData: {},
    };
  },
  created() {
    if (this.$route.params.value1) {
      this.orderDate = this.$route.params.value1;
    }
    if (this.$route.params.status) {
      this.orderStatus = this.$route.params.status;
    }
    this.getOrderIndex();
    this.getExpressList();
    this.getOrderList();
    // this.getProductList()
  },
  mounted() {
    this.getStrongReminderRadio();
    this.getOrderStatistics();
  },
  watch: {
    detailVisible(newName, oldName) {
      if (newName === false) {
        this.productInput = "";
        this.orderState = "";
      }
    },
  },
  computed: {
    shopInfo() {
      return JSON.parse(window.localStorage.getItem("shopInfo")) || {};
    },
    token: {
      get() {
        return window.localStorage.getItem("token") || "";
      },
    },
  },
  methods: {
    getOrderStatistics() {
      // 获取采购统计
      this.$http2
        .get("/merapi/shop/getOrderStatistics")
        .then((res) => {
          this.orderStatistics = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    getRealTimeMessage() {
      this.$http2
        .get("/merapi/message/home")
        .then((res) => {
          this.realTimeMessage = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    orderTypeChange(e) {
      if (e == 1) {
        this.orderTypeDisabled = true;
        this.order_pay_mode = 1;
      }
      if (e == 2) {
        this.orderTypeDisabled = false;
      }
    },
    payTypeChange(e) {
      console.log(e);
    },
    getStrongReminderRadio() {
      this.$http2
        .post("/merapi/shop/sms/radio")
        .then((res) => {
          this.radioList = res.data.data;
          if (!this.radioList[0].radio && !this.radioList[1].radio) {
            store.dispatch("setAgreementVisibleFlag", false);
            localStorage.setItem("reminderVisible", 0);
            this.is_signed = true;
          } else {
            this.is_signed = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    importDialogClose() {
      this.importPage = 1;
      this.order_item_stock_status = "";
      this.order_item_check_result = "";
    },
    querySelectChange(e) {
      this.queryValue = e;
      if (this.queryValue == 2) {
        this.batchQueryVisible = true;
      }
    },
    openCreateOrder() {
      // let is_check = this.shopInfo.is_check || 0
      // if (is_check != 1) {
      //   store.dispatch('setAgreementVisibleFlag2', true)
      // } else {
      //   this.orderForm = {
      //     shop_order: '',
      //     consigner: '',
      //     phone: '',
      //     card_no: '',
      //     province: "",
      //     transaction_id: "",
      //     city: "",
      //     area: "",
      //     address: '',
      //     remark: '',
      //     orderer: '',
      //     order_price: ''
      //   }
      //   this.checkProducts = [],
      //     this.dialogFormVisible = true
      // }
      this.dialogFormVisible = true;
    },
    openCreateOrderList() {
      // let is_check = this.shopInfo.is_check || 0
      // if (is_check != 1) {
      //   store.dispatch('setAgreementVisibleFlag2', true)
      // } else {
      //   this.uploadVisible = true
      // }
    },

    intelligentIdentification() {
      this.$http2
        .post("/merapi/ocr/ocr_out_put", { text: this.textarea })
        .then((res) => {
          if (res.data.status === 200) {
            let data = res.data.data;
            let addressDetail = data.town + data.detail;
            if (data.county.indexOf("县") != -1 && data.province == "重庆市") {
              this.$set(this.orderForm, "city", "县");
            } else {
              this.$set(this.orderForm, "city", data.city);
            }
            this.$set(this.orderForm, "consigner", data.person);
            this.$set(this.orderForm, "orderer", data.person);
            this.$set(this.orderForm, "phone", data.phonenum);
            this.$set(this.orderForm, "address", addressDetail);

            this.$set(this.orderForm, "area", data.county);
            this.$set(this.orderForm, "province", data.province);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    setProductList(query) {
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id;
      // eslint-disable-next-line camelcase
      var keyword = query || "";
      this.$http2
        .post("/merapi/stock/product_stock_list", {
          page: 1,
          keyword,
          limit: 1000,
        })
        .then((res) => {
          this.productList = res.data.data.list;
        })
        .catch((err) => {
          this.$message.error(err + "");
        });
    },
    parentFn(payload) {
      this.orderForm.order_price = payload;
    },
    checkProductList(array) {
      this.checkProducts = array;
      if (!this.checkWeight()) {
        this.$alert("每单商品总重量不要超过15KG", {
          confirmButtonText: "确定",
          type: "error",
        });
      }
    },
    checkWeight() {
      let totalWeight = 0;
      this.checkProducts.forEach((item) => {
        totalWeight += Number(item.spec_kg || 0) * Number(item.number);
        // if (item.single_name.indexOf('KG') != -1) {
        //   totalWeight += parseInt(item.single_name) * Number(item.number)
        // } else if (item.single_name.indexOf('LB') != -1) {
        //   totalWeight += parseInt(item.single_name) * Number(item.number) * 0.4536
        // }
      });

      if (totalWeight > 15) {
        return false;
      } else {
        return true;
      }
    },
    submitOrder() {
      // 提交订单
      this.$refs.submitFormRef.validate((valid) => {
        if (valid) {
          var data = this.orderForm;
          data.products = this.checkProducts;
          if (!this.checkWeight()) {
            return this.$alert("您所添加的商品已超重", {
              type: "error",
              confirmButtonText: "确定",
            });
          }
          if (data.province === "" || data.city === "" || data.area === "") {
            return this.$alert("请您完整的填写地址", {
              type: "error",
              confirmButtonText: "确定",
            });
          }
          if (
            !data.products.length ||
            !data.order_price ||
            data.order_price <= 0
          ) {
            return this.$alert("请您正确的填写商品信息", {
              type: "error",
              confirmButtonText: "确定",
            });
          }
          this.subLoading = true;
          this.$http2
            .post("/merapi/order/create", data)
            .then((res) => {
              this.subLoading = false;
              // eslint-disable-next-line
              if (res.status == "200" && res.data.code == 0) {
                this.getOrderList();
                this.getOrderIndex();
                this.getExpressList();
                this.resetOrderForm();
                this.dialogFormVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                  duration: 2000,
                });
              } else {
                return this.$alert(res.data.msg, {
                  type: "error",
                  confirmButtonText: "确定",
                });
              }
            })
            .catch((err) => {
              this.subLoading = false;
              return this.$alert(err, {
                type: "error",
                confirmButtonText: "确定",
              });
            });
        }
      });
    },
    changeGateway(data) {
      this.productDetail = data;
    },
    onChangeProvince(data) {
      this.orderForm.province = data.value;
    },
    onChangeCity(data) {
      this.orderForm.city = data.value;
    },
    onChangeArea(data) {
      this.orderForm.area = data.value;
    },
    resetOrderForm() {
      this.orderForm = {
        shop_order: "",
        consigner: "",
        phone: "",
        card_no: "",
        province: "",
        remark: "",
        city: "",
        area: "",
        address: "",
        order_price: "",
      };
      this.checkProducts = [];
    },
    cancelOrder(e) {
      // 订单取消
      let nowDate = new Date().getTime();
      let orderTime = new Date(e.order_time).getTime();
      let timeDifference = nowDate - orderTime;
      if (timeDifference > 1200000 && e.flag_name != "快递停发") {
        return this.$alert(
          "下单超过20分钟，请联系售后人员取消订单！",
          "消息提示",
          {
            callback: function () {},
          }
        );
      }

      // eslint-disable-next-line camelcase
      var order_id = e.order_id;

      this.$confirm("您确认取消这个订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http2
            .post("/merapi/order/order_cancel", { order_id })
            .then((res) => {
              if (res.data.status === 200 || res.data.code == 0) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
              this.getOrderList();
              this.getOrderIndex();
            })
            .catch((err) => {
              return this.$message.error(err);
            });
        })
        .catch(() => {});
    },
    setVisible(data) {
      // 修改信息组件传值
      this.editOrderVisible = data;
      this.getOrderList();
    },
    tableRowClassName({ row, rowIndex }) {
      // eslint-disable-next-line eqeqeq
      if (row.stock_status == "库存不足") {
        return "bgcred";
      }
      // eslint-disable-next-line eqeqeq
      if (row.check_status != 1) {
        return "bgcred";
      }
      return "";
    },
    templateDownload() {
      window.open(
        "https://source.aidogcatpet.com/data-file/%E6%94%AF%E4%BB%98%E5%AE%9D-%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E8%AE%A2%E5%8D%95%E6%A8%A1%E6%9D%BF2.0.xlsx"
      );
    },
    handleSizeChange(e) {
      this.limit = e;
      this.getOrderList();
    },
    selectOrderStatus(e) {
      // 选择订单状态
      this.orderStatus = e;
      this.getOrderList("nopage");
    },
    remoteMethod(query) {
      // 下拉列表筛选
      this.getExpressList(query);
    },
    curretnImportChange(e) {
      this.importPage = e;
      this.getImportList();
    },
    curretnZFBImportChange(e) {
      this.importPage = e;
      this.getImportList2();
    },
    uploadFileSuccess(res) {
      this.importLoading = false;
      // eslint-disable-next-line eqeqeq
      if (res.code == 0) {
        // this.importList = res.data.list\

        this.ImportTotal = res.count;
        if (res.data.length > 0) {
          this.form_id = res.data[0].form_id;
        }
        this.dialogVisible = true;
        this.uploadVisible = false;
        this.getImportList();
      } else {
        this.$confirm(res.msg, {
          confirmButtonText: "关闭",
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
        // this.$message.error(res.msg)
      }
    },
    async beforeZFBUpload() {
      // if (!this.is_signed) {
      //   this.$confirm("由于您的店铺未完成《跨境商品推广服务协议》的签署，暂时无法下单，请先署协议后再来下单！", {
      //     confirmButtonText: '关闭',
      //     dangerouslyUseHTMLString: true,
      //     type: 'error',
      //   })
      //   return false
      // }
      let currentDate = new Date();
      let startDate = new Date("2024-03-04 20:00:00");
      let endDate = new Date("2024-03-08 23:59:59");
      if (
        this.order_model == 1 &&
        currentDate >= startDate &&
        currentDate <= endDate
      ) {
        let data = await this.$confirm(
          "3月4日20:00-3月8日23:59 ，3.8焕新周期间，商品直降发货价格（只针对共享模式下单）“如使用采购模式下单，只会扣除已有库存，将无法享受3.8焕新周中促折扣！”。",
          {
            confirmButtonText: "我已知晓，继续导单",
            dangerouslyUseHTMLString: true,
            type: "warning",
          }
        );
        if (data != "confirm") {
          return false;
        }
      } else {
        this.importZFBLoading = true;
      }
      // this.importZFBLoading = true;
    },
    uploadZFBSuccess(res) {
      this.importZFBLoading = false;
      // eslint-disable-next-line
      if (res.code == 0 || res.code == 200) {
        // this.importList = res.data.list
        this.ImportTotal = res.count;
        if (res.data.length > 0) {
          this.form_id = res.data[0].form_id;
        }
        this.dialogVisible2 = true;

        this.getImportList2();
      } else {
        this.$confirm(res.msg, {
          confirmButtonText: "关闭",
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
        // this.$message.error(res.msg)
      }
    },
    getImportList2(page) {
      // 获取支付宝导入列表

      // eslint-disable-next-line camelcase
      var form_id = this.form_id;
      this.importPage = page || this.importPage;
      var page = this.importPage;
      var limit = this.importLimit;
      var order_item_check_result = this.order_item_check_result;
      var order_item_stock_status = this.order_item_stock_status;
      this.$http2
        .post("/merapi/order/alipay_import_list", {
          form_id,
          page,
          limit,
          order_item_stock_status,
          order_item_check_result,
        })
        .then((res) => {
          this.importZFBList = res.data.data.list;
          this.importZFBTotal = res.data.data.total;
          this.abnormalCount = res.data.data.abnormal_count;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },

    getImportList(page) {
      // 获取导入列表

      // eslint-disable-next-line camelcase
      var form_id = this.form_id;
      this.importPage = page || this.importPage;
      var page = this.importPage;
      var limit = this.importLimit;
      var order_item_check_result = this.order_item_check_result;
      var order_item_stock_status = this.order_item_stock_status;
      this.$http2
        .post("/merapi/order/import/lst", {
          form_id,
          page,
          limit,
          order_item_stock_status,
          order_item_check_result,
        })
        .then((res) => {
          this.importList = res.data.data.list;
          this.ImportTotal = res.data.data.total;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },

    orderDetailExport() {
      // 订单商品明细导出
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id;
      var keyword = this.productInput;
      // var status = this.orderState
      // eslint-disable-next-line camelcase
      var order_id = this.currentOrderDetail.order_id;
      this.$http
        .post("/v1/order/detail/item_export", { shop_id, order_id, keyword })
        .then((res) => {
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    changeOrderVisible(e) {
      // 修改订单组件显示
      this.currentOrderDetail = e;
      this.editOrderVisible = true;
    },
    getOrderDetail(e) {
      // 获取订单详情
      this.detailList = [];
      // eslint-disable-next-line eqeqeq
      this.detailPage = e == "nopage" ? 1 : this.detailPage;
      e = e === "nopage" ? this.currentOrderDetail : e;
      this.currentOrderDetail = e;
      // eslint-disable-next-line camelcase
      var order_id = this.currentOrderDetail.order_id;

      var keyword = this.productInput;
      var limit = 100;
      // var status = this.orderState
      this.detailVisible = true;
      this.$http2
        .post("/merapi/order/detail/list", { order_id, keyword, limit })
        .then((res) => {
          this.detailList = res.data.data.list;
          this.detailTotal = res.data.data.total;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    orderAgain(data) {
      let order_id = data.order_id;
      let status = data.order_source;
      if (status == 5) {
        this.$router.push({
          path: "/generation",
          query: {
            id: order_id,
          },
        });
      } else {
        this.$http2
          .get("/merapi/order/detail", { params: { order_id } })
          .then((res) => {
            let result = res.data.data;
            let is_check = this.shopInfo.is_check || 0;
            if (is_check != 1) {
              store.dispatch("setAgreementVisibleFlag2", true);
            } else {
              this.dialogFormVisible = true;
              let arr = [];
              result.products.forEach((item, index) => {
                let {
                  product_id,
                  bar_code,
                  price,
                  store_name,
                  single_name,
                  number,
                  total_price,
                } = item;
                arr.push({
                  product_id,
                  bar_code,
                  store_name,
                  price,
                  single_name,
                  number,
                  total_price,
                });
              });
              this.checkProducts = arr;
              this.orderForm = {
                consigner: result.consigner,
                phone: result.phone,
                province: result.province,
                order_price: result.order_price,
                city: result.city,
                card_no: result.card_no,
                consigner: result.consignee,
                orderer: result.orderer,
                area: result.area,
                address: result.address,
              };
            }
          })
          .catch((err) => {
            return this.$message.error(err);
          });
      }
    },
    orderImportConfirm() {
      // 订单确认
      this.orderLoading = true;
      // eslint-disable-next-line camelcase
      var form_id = this.form_id;
      this.$http2
        .post("/merapi/order/import/confirm", { form_id })
        .then((res) => {
          if (res.data.status === -1) {
            this.$message.error(res.data.msg);
          } else {
            this.dialogVisible = false;
            this.$message.success(res.data.msg);
          }
          this.getOrderList();
          this.orderLoading = false;
        })
        .catch((err) => {
          this.dialogVisible = false;
          this.orderLoading = false;
          return this.$message.error(err);
        });
    },
    orderZFBImportConfirm() {
      if (this.abnormalCount) {
        return this.$message.error("请先处理异常订单");
      }
      // 订单确认
      this.orderLoading = true;
      // eslint-disable-next-line camelcase
      var form_id = this.form_id;
      this.$http2
        .post("/merapi/order/alipay_import_confirm", {
          form_id,
          order_model: this.order_model,
          order_pay_mode: this.order_pay_mode,
        })
        .then((res) => {
          if (res.data.status === -1) {
            this.$message.error(res.data.msg);
          } else {
            this.dialogVisible2 = false;
            this.$message.success(res.data.msg);
          }
          this.getOrderList();
          this.orderLoading = false;
          this.uploadZFBVisible = false;
        })
        .catch((err) => {
          this.dialogVisible = false;
          this.orderLoading = false;
          return this.$message.error(err);
        });
    },
    orderPrevieweExport() {
      // 缺货商品导出
      // eslint-disable-next-line camelcase
      var form_id = this.form_id;
      var token = window.localStorage.getItem("token");

      try {
        let params = {
          form_id,
          token,
        };
        let data = urlEncode(params);
        window.location.href =
          BASE_URL2 + "/merapi/order/preview/export?" + data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },
    orderZFBPrevieweExport() {
      // 缺货商品导出
      // eslint-disable-next-line camelcase
      var form_id = this.form_id;
      var token = window.localStorage.getItem("token");

      try {
        let params = {
          form_id,
          token,
        };
        let data = urlEncode(params);
        window.location.href =
          BASE_URL2 + "/merapi/order/preview/export?" + data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },
    removeOrder(e) {
      // 移除订单

      // eslint-disable-next-line camelcase
      var record_id = e.id;
      var importList = this.importList;
      this.$http2
        .post("/merapi/order/import/remove", { record_id })
        .then((res) => {
          this.importList = importList.filter((item) => {
            // eslint-disable-next-line camelcase
            return item.id !== record_id;
          });
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    removeZFBOrder(e) {
      // 移除订单

      // eslint-disable-next-line camelcase
      var record_id = e.id;
      // var importZFBList = this.importZFBList
      this.$http2
        .post("/merapi/order/alipay_import_remove", { record_id })
        .then((res) => {
          // this.importZFBList = importZFBList.filter(item => {
          //   // eslint-disable-next-line camelcase
          //   return item.id !== record_id
          // })
          this.$message.success(res.data.msg);
          this.getImportList2();
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    beforeUpload(file) {
      this.importLoading = true;
      // 上传文件之前
    },
    prevClick(e) {
      // 点击上一页
      this.page = e;
      this.getOrderList();
    },
    nextClick(e) {
      // 点击下一页
      this.page = e;
      this.getOrderList();
    },
    curretnChange(e) {
      // 切换page
      this.page = e;
      this.getOrderList();
    },
    curretnDetailChange(e) {
      this.detailPage = e;
      this.getOrderDetail("nopage");
    },
    handleSelectionChange(val) {
      // 多选框切换
      this.multipleSelection = val;
    },
    getOrderIndex() {
      // 获取订单首页
      let that = this;
      var more_order_no = this.textareaOrderList.split(/[(\r\n)\r\n]/);
      more_order_no = more_order_no.filter(that.del_apace);
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id;
      // eslint-disable-next-line camelcase
      var express_id = this.logisticsValue;
      // eslint-disable-next-line camelcase
      var order_status = this.orderStatus;
      var flag_name = this.order_tag;
      var keyword = this.input;
      // eslint-disable-next-line camelcase
      var order_date = "";
      if (
        Object.prototype.toString.call(this.orderDate) === "[object Array]" &&
        this.orderDate[0]
      ) {
        // eslint-disable-next-line camelcase
        order_date = formatDateArray(this.orderDate[0], this.orderDate[1]);
      }

      // eslint-disable-next-line camelcase
      var delivery_date = "";
      if (
        Object.prototype.toString.call(this.deliveryDate) ===
          "[object Array]" &&
        this.deliveryDate[0]
      ) {
        // eslint-disable-next-line camelcase
        delivery_date = formatDateArray(
          this.deliveryDate[0],
          this.deliveryDate[1]
        );
      }
      this.$http2
        .post("/merapi/order/index", {
          flag_name,
          shop_id,
          more_order_no,
          express_id,
          keyword,
          order_date,
          delivery_date,
        })
        .then((res) => {
          this.orderIndex = res.data.data.order_status;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    del_apace(s) {
      return s && s.trim();
    },
    getOrderList2(e) {
      // 获取订单列表
      let that = this;
      var more_order_no = this.textareaOrderList.split(/[(\r\n)\r\n]/);
      more_order_no = more_order_no.filter(that.del_apace);
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id;
      // eslint-disable-next-line camelcase
      var express_id = this.logisticsValue;
      // eslint-disable-next-line camelcase
      var order_status = this.orderStatus;
      var flag_name = this.order_tag;
      var keyword = this.input;
      // eslint-disable-next-line camelcase
      var order_date = "";
      if (
        Object.prototype.toString.call(this.orderDate) === "[object Array]" &&
        this.orderDate[0]
      ) {
        // eslint-disable-next-line camelcase
        order_date = formatDateArray(this.orderDate[0], this.orderDate[1]);
      }

      // eslint-disable-next-line camelcase
      var delivery_date = "";
      if (
        Object.prototype.toString.call(this.deliveryDate) ===
          "[object Array]" &&
        this.deliveryDate[0]
      ) {
        // eslint-disable-next-line camelcase
        delivery_date = formatDateArray(
          this.deliveryDate[0],
          this.deliveryDate[1]
        );
      }

      this.page = e === "nopage" ? 1 : this.page;
      var page = this.page;
      var limit = this.limit;
      this.$http2
        .post("/merapi/order/list", {
          page,
          limit,
          flag_name,
          shop_id,
          more_order_no,
          express_id,
          keyword,
          order_status,
          order_date,
          delivery_date,
        })
        .then((res) => {
          this.orderList = res.data.data.list;
          this.total = res.data.data.total;
          this.batchQueryVisible = false;
          this.getOrderIndex();
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    getOrderList(e) {
      this.textareaOrderList = "";
      // 获取订单列表
      var more_order_no = this.textareaOrderList.split(/[(\r\n)\r\n]/);

      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id;
      // eslint-disable-next-line camelcase
      var express_id = this.logisticsValue;
      // eslint-disable-next-line camelcase
      var order_status = this.orderStatus;
      var flag_name = this.order_tag;
      var keyword = this.input;
      // eslint-disable-next-line camelcase
      var order_date = "";
      if (
        Object.prototype.toString.call(this.orderDate) === "[object Array]" &&
        this.orderDate[0]
      ) {
        // eslint-disable-next-line camelcase
        order_date = formatDateArray(this.orderDate[0], this.orderDate[1]);
      }

      // eslint-disable-next-line camelcase
      var delivery_date = "";
      if (
        Object.prototype.toString.call(this.deliveryDate) ===
          "[object Array]" &&
        this.deliveryDate[0]
      ) {
        // eslint-disable-next-line camelcase
        delivery_date = formatDateArray(
          this.deliveryDate[0],
          this.deliveryDate[1]
        );
      }

      this.page = e === "nopage" ? 1 : this.page;
      var page = this.page;
      var limit = this.limit;
      this.$http2
        .post("/merapi/order/list", {
          page,
          limit,
          flag_name,
          shop_id,
          more_order_no,
          express_id,
          keyword,
          order_status,
          order_date,
          delivery_date,
        })
        .then((res) => {
          this.orderList = res.data.data.list;
          this.total = res.data.data.total;
          this.batchQueryVisible = false;
          this.getOrderIndex();
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    orderExport() {
      // 订单导出
      let that = this;
      var more_order_no = this.textareaOrderList.split(/[(\r\n)\r\n]/);
      more_order_no = more_order_no.filter(that.del_apace);
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id;
      // eslint-disable-next-line camelcase
      var express_id = this.logisticsValue;
      var keyword = this.input;
      var flag_name = this.order_tag;
      // eslint-disable-next-line camelcase
      var order_date = "";
      // eslint-disable-next-line camelcase
      var order_status = this.orderStatus;
      if (
        Object.prototype.toString.call(this.orderDate) === "[object Array]" &&
        this.orderDate[0]
      ) {
        // eslint-disable-next-line camelcase
        order_date = formatDateArray(this.orderDate[0], this.orderDate[1]);
      }

      // eslint-disable-next-line camelcase
      var delivery_date = "";
      if (
        Object.prototype.toString.call(this.deliveryDate) ===
          "[object Array]" &&
        this.deliveryDate[0]
      ) {
        // eslint-disable-next-line camelcase
        delivery_date = formatDateArray(
          this.deliveryDate[0],
          this.deliveryDate[1]
        );
      }
      var multipleSelection = this.multipleSelection;
      // eslint-disable-next-line camelcase
      var ids = [];
      multipleSelection.forEach((item) => {
        ids.push(item.order_id);
      });
      // eslint-disable-next-line camelcase
      ids = ids.join(",");
      var token = window.localStorage.getItem("token");

      try {
        let params = {
          shop_id,
          express_id,
          order_date,
          flag_name,
          more_order_no,
          keyword,
          order_status,
          delivery_date,
          token,
          ids,
        };
        let data = urlEncode(params);
        window.location.href = BASE_URL2 + "/merapi/order/export?" + data;
      } catch {
        this.$message.error("服务器繁忙，请稍后重试");
      }
    },

    getExpressList(e) {
      // 获取物流公司列表
      var shop_id = this.shopInfo.shop_id;
      // eslint-disable-next-line camelcase
      var name = e ? e : "";
      this.$http2
        .get("/merapi/express/list", { params: { shop_id, name } })
        .then((res) => {
          this.express = res.data.data.list;
        });
    },
  },
};
</script>

<style scoped lang="less">
.module {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: space-between;
  margin-bottom: 30px;

  .item {
    margin-top: 20px;
    width: 30%;
    height: 180px;
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(84, 95, 167, 0.1);
    font-family: Roboto;
    border: 0px solid rgba(239, 242, 245, 1);
    position: relative;
    padding: 25px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .trend {
      font-size: 12px;
      color: #75848c;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        color: #50bda0;
        font-weight: normal;
        margin-right: 20px;
      }
    }
    .down {
      font-size: 12px;
      color: #75848c;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        color: #d91d48;
        font-weight: normal;
        margin-right: 20px;
      }
    }

    .des {
      color: #75848c;
      font-size: 16px;
      // margin-bottom: 15px;
    }

    .dfcs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 72px;
        height: 72px;
      }
    }

    span {
      font-size: 26px;
      font-weight: 600;
    }

    .el-icon-warning {
      position: absolute;
      top: 25px;
      font-size: 24px;
      color: #d2d6d9;
      right: 25px;
    }
  }
}
.mes-title {
  text-align: center;
  font-size: 21px;
  margin: 60px 0 50px 0;
}

.weixin-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-table/deep/.el-table__body tr.bgcred > td {
  background-color: rgb(245, 108, 108) !important;
}

.el-form /deep/ .distpicker-address-wrapper select {
  height: 40px;
}

.el-form {
  /deep/.el-input.is-disabled .el-input__inner {
    color: #000;
  }
}
.abnormal {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  color: #75848c;
}
.bgcblue {
  background-color: #ecf5ff;
  padding: 8px 12px;
  border-radius: 5px;
  color: #1684fc;
  border: 1px solid #1684fc;
}

.state {
  display: flex;
  align-items: center;
  font-size: 16px;

  div {
    margin-right: 20px;
  }
}

.date {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;

  .down-order {
    margin-right: 30px;
  }
}

.select {
  display: flex;
  margin-top: 20px;
  align-items: center;
  font-size: 16px;
  // justify-content: space-between;
  .logistics {
    margin: 0;
  }

  .el-button {
    margin-left: 20px;
  }

  .input {
    display: flex;
    align-items: center;

    div {
      width: 100px;
      font-size: 16px;
    }

    .el-input {
      width: 400px;
      font-size: 14px;
    }
  }
}

.demo-input-suffix {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table {
  margin-top: 30px;

  .text {
    font-size: 12px;
    // white-space :nowrap;
  }
}

.el-table /deep/ .bgcred {
  // background-color: rgb(245, 108, 108);
  color: rgb(245, 108, 108);
}

.el-table /deep/ .bgcgreen {
  // background-color: rgb(245, 108, 108);
  color: green;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  align-items: center;
}

.detail {
  font-size: 16px;
  display: flex;
  justify-content: space-between;

  div {
  flex: 1;
    margin: 10px 30px 0 0;
  }
}

.el-dialog {
  .down-order {
    font-size: 16px;
    margin-top: 10px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .el-button {
    margin-left: 0;
  }
}

.query-select {
  width: 30px;

  /deep/ .el-input__inner {
    padding: 0;
    border-radius: 0 5px 5px 0;
  }
}

.query-button {
  border-radius: 5px 0 0 5px !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .el-button {
    margin-left: 20px;
  }
}

.tips-box {
  font-size: 14px;
  margin: 20px 0;
  color: blue;

  .first-div {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.order-num {
  font-size: 16px;
  color: red;
  margin-left: 20px;
}
.order-detail .el-dialog__body {
  .detail {
    margin-top: -20px;
  }
}
// .formDialog {
//   height: 500px;
//   overflow-y: scroll;
// }
</style>

<template>
  <div class="container">
    <el-dialog
      title="信息编辑"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="editOrderVisible"
      width="50%"
    >
      <div class="content">
        <el-form ref="form" :rules="formRules" :model="form" label-width="120px">
          <el-form-item prop="real_name" label="真实姓名">
            <el-input v-model="form.real_name"></el-input>
          </el-form-item>
          <el-form-item prop="card_id" label="身份证号">
            <el-input v-model="form.card_id"></el-input>
          </el-form-item>

          <el-form-item prop="phone" label="手机号">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item prop="province" label="省份">
            <el-input v-model="form.province"></el-input>
          </el-form-item>
          <el-form-item prop="city" label="城市">
            <el-input v-model="form.city"></el-input>
          </el-form-item>
          <el-form-item prop="area" label="地区">
            <el-input v-model="form.area"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="详细地址">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVisible">取 消</el-button>
        <el-button type="primary" @click="orderInfoSetting">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'information',
  data () {
    return {
      form: {
        // 表单
        real_name: '',
        card_id: '',
        phone: '',
        city: '',
        area: '',
        address: '',
        province: ''
      },
      imageUrl: '',
      storeInfo: {},
      // 表单校验
      formRules: {
        real_name: [{ required: true, message: '真实性名必填' }],
        card_id: [{ required: true, message: '身份证号必填' }],
        phone: [{ required: true, message: '电话必填' }],
        province: [{ required: true, message: '省市必填' }],
        address: [{ required: true, message: '地址必填' }],
        area: [{ required: true, message: '地区必填' }],
        city: [{ required: true, message: '城市必填' }]
      }
    }
  },
  props: {
    editOrderVisible: {
      type: Boolean,
      default: false
    },
    orderInfo: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  watch: {
    orderInfo (newData, oldData) {
      this.form.address = newData.address
      this.form.real_name = newData.real_name
      this.form.card_id = newData.card
      this.form.phone = newData.phone
      this.form.city = newData.city
      this.form.area = newData.area
      this.form.province = newData.province
    }
  },
  computed: {
    shopInfo () {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    },
    token: {
      get () {
        return window.localStorage.getItem('token') || ''
      }
    }
  },
  created () {
  },
  methods: {
    handleAvatarError () {
      this.$message.error('服务器繁忙，请稍后再试')
    },

    orderInfoSetting () {
      this.$refs.form.validate(valid => {
        if (valid) {
          var data = this.form
          data.shop_id = this.shopInfo.shop_id
          data.order_id = this.orderInfo.order_id
          this.$http
            .post('/v1/order/edit', data)
            .then(res => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg)
              } else if (res.data.status == 400) {
                this.$message.error(res.data.msg)
              }
              this.changeVisible()
            })
            .catch(err => {
              return this.$message.error(err)
            })
        }
      })
    },
    changeVisible () {
      this.$emit('changeDialogVisible', false)
    }
  }
}
</script>

<style  lang="less">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
